<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Graduated students</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Graduated students
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <b-dropdown
                    v-if="checkIsAccessible('import-export', 'export-student')"
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4"
                    no-caret
                    right
                    no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover">
                      <b-dropdown-text
                        tag="div"
                        class="navi-item"
                        v-if="checkIsAccessible('import-export', 'export-student')"
                      >
                        <a @click="exportData" class="navi-link">
                          <span class="navi-icon">
                            <i class="fa fa-file-csv"></i>
                          </span>
                          <span class="navi-text">Export</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row px-2">
                <v-col cols="12" md="2">
                  <v-text-field
                    class="form-control"
                    v-model="search.name"
                    label="Name, phone, email, symbol no."
                    outlined
                    clearable
                    @input="search.name = $event !== null ? $event : ''"
                    v-on:keyup.enter="searchGraduatedStudents()"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    :items="academicYears"
                    class="form-control"
                    :menu-props="{ button: true, offsetY: true }"
                    v-model="search.academic_year_id"
                    label="Academic year"
                    item-value="id"
                    item-text="year"
                    @input="search.academic_year_id = $event !== null ? $event : ''"
                    outlined
                    v-on:keyup.enter="searchGraduatedStudents()"
                    clearable
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    :items="levels"
                    :menu-props="{ button: true, offsetY: true }"
                    class="form-control"
                    v-model="search.level_id"
                    label="Level"
                    item-value="id"
                    item-text="title"
                    @input="search.level_id = $event !== null ? $event : ''"
                    outlined
                    v-on:keyup.enter="searchGraduatedStudents()"
                    @change="getPrograms"
                    clearable
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    outlined
                    dense
                    v-model="search.program_id"
                    :items="programs"
                    label="Programs"
                    @input="search.program_id = $event !== null ? $event : ''"
                    item-value="id"
                    :menu-props="{ button: true, offsetY: true }"
                    item-text="title"
                    v-on:keyup.enter="searchGraduatedStudents()"
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    outlined
                    dense
                    v-model="search.sort_by"
                    :items="sort_by"
                    :menu-props="{ button: true, offsetY: true }"
                    label="Sort by"
                    v-on:keyup.enter="searchGraduatedStudents()"
                    item-value="value"
                    @input="search.sort_by = $event !== null ? $event : ''"
                    item-text="title"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2" class="text-right">
                  <v-btn
                    :loading="loading"
                    @click.prevent="searchGraduatedStudents()"
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              ></v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <th class="px-3" colspan="2" style="width: 450px !important; white-space: pre-wrap;">Student detail</th>
                  <th class="px-3 wrap-column">Program</th>
                  <th class="px-3 wrap-column">Academic Year</th>
                  <th class="px-3 wrap-column">Graduated Year</th>
                  <th class="pr-3 text-center"></th>
                </thead>
                <tbody>
                  <tr
                    v-show="users.length > 0"
                    v-for="(item, index) in users"
                    :key="index"
                  >
                    <td class="px-3 wrap-column" style="width: 50px !important; white-space: pre-wrap;">
                      <div class="symbol-label mt-2">
                        <img
                          v-if="item.image_path && item.image_path.real"
                          :src="item.image_path.real"
                          class="cursor-pointer"
                          alt=""
                          @click="changeImage(item)"
                          style="height: 30px"
                        />
                        <span
                          v-else
                          class="symbol symbol-35 symbol-light-success"
                          @click="changeImage(item)"
                        >
                          <span
                            class="symbol-label font-size-sm font-weight-bold cursor-pointer"
                          >
                            {{
                              item.first_name
                                ? item.first_name.charAt(0).toUpperCase()
                                : ""
                            }}
                            {{
                              item.last_name ? item.last_name.charAt(0).toUpperCase() : ""
                            }}</span
                          >
                        </span>
                      </div>
                    </td>
                    <td class="wrap-column px-3" style="width: 400px !important; white-space: pre-wrap;">
                      <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                        {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
                      </a>|
                      <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                        <b> {{ item.personal_id }} </b> <i class="fa fa-book-open font-size-xs"></i> <span class="badge-dot"
                                                                                                           v-bind:class="{ 'bg-color-green': item.is_active, 'bg-color-red': !item.is_active }">
                        </span><br />
                      </a>
                      <span class="text-secondary" v-if="item.setting && item.setting.midas_id">
                        <b> External ID:</b> {{ item.setting.midas_id != null ? item.setting.midas_id : 'N/A' }}
                      </span>
                    </td>
                    <td class="wrap-column px-3">
                      <simplebold>
                        {{
                          item.setting && item.setting.program_title
                            ? item.setting.program_title
                            : "N/A"
                        }}
                      </simplebold>
                    </td>
                    <td>
                      <simplebold>{{ item.setting.academic_year }}</simplebold>
                    </td>
                    <td class="px-3">
                      <simplebold>{{ item.graduated_year }}</simplebold>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('user', 'edit')">
                              <a class="navi-link" @click="openSummaryPage(item)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text
                              v-if="checkIsAccessible('user', 'delete')"
                              tag="div"
                              class="navi-item"
                            >
                              <a class="navi-link" @click="deleteUser(item.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="users.length == 0">
                    <td class="px-3 text-center" colspan="6">No data available.</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="users.length > 0"
                class="pull-right mt-7"
                @input="getGraduatedStudents"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                :loading="loading"
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import userService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import LevelService from "@/core/services/level/LevelService";
const levelService = new LevelService();
const academicYearService = new AcademicYearService();
const programService = new ProgramService();
const user = new userService();
export default {
  data() {
    return {
      search: {
        name: "",
        type: "student",
        limit: 25,
        sort_by: "ASC",
      },
      users: [],
      loading: false,
      page: null,
      total: 0,
      totalPage: null,
      perPage: 25,
      sort_by: [
        { value: "ASC", title: "A-Z" },
        { value: "DESC", title: "Z-A" },
      ],
      levels: [],
      programs: [],
      academicYears: [],
    };
  },
  methods: {
    searchGraduatedStudents() {
      this.page = 1;
      this.getGraduatedStudents();
    },
    getGraduatedStudents() {
      this.loading = true;
      user
        .getAllGraduatedStudent(this.search, this.page)
        .then((response) => {
          this.users = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.totalPage = response.data.meta.last_page;
          this.perPage = parseInt(response.data.meta.per_page);
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    exportData() {
      user
        .exportGraduatedStudent(this.search)
        .then(() => {})
        .catch(() => {
          //console.log(error);
        });
    },
    openSummaryPage(item) {
        if(this.checkIsAccessible('user', 'list')) {
          this.$router.push({
            name: "students-summary",
            params: { id: item.id, type: item.type[0] },
          });
        }
    },
    getAcademicYears() {
      academicYearService.all().then((response) => {
        this.academicYears = response.data;
      });
    },
    getLevels() {
      levelService.all().then((response) => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then((response) => {
        this.programs = response.data;
      });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            userService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");
                this.getGraduatedStudents();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
  },
  mounted() {
    this.getAcademicYears();
    this.getLevels();
    this.getGraduatedStudents();
  },
};
</script>
